import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { useParams, useNavigate } from 'react-router-dom';
import { jsonConfig } from '../../Config';
import { PencilIcon } from '@heroicons/react/20/solid'
import UploadLogo from '../../Components/Upload/Configuracoes'
import ShowFile from '../../Components/ShowImageUpload/Configuracoes'
import ModalConfiguracao from '../../Components/Modal/ConcluirConfiguracao'

const CompletarConfiguracao = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin, checkConfigCliente, postConfigCliente, putConfigCliente,
        postConfigCategoriaServico, getConfigCategoriaServico,
        postConfigContabilidade,
        getConfigContabilidade,
        postConfigPrestadorMyProfile,
        postPrestadorNewProfile
    } = useContext(AuthContextApi)
    const { token } = useParams()
    const navigate = useNavigate()

    const stepValue = 25;



    const [percentSteps, _] = useState(0)
    const [width, setWidth] = useState((percentSteps / 100) * 100)
    const [nome, setNome] = useState('')
    const [configCliente, setConfigCliente] = useState({})
    const [empresa, setEmpresa] = useState(false)

    const [step1, setStep1] = useState(false)
    const [editStep1, setEditStep1] = useState(false)

    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)
    const [step4, setStep4] = useState(false)

    const [logo, setLogo] = useState('')
    const [errorLogo, setErrorLogo] = useState(false)
    const [errorLogoMessage, setErrorLogoMessage] = useState('Campo obrigatório.')

    const [horarioAbertura, setHorarioAbertura] = useState('')
    const [horarioFechamento, setHorarioFechamento] = useState('')
    const [corPrimaria, setCorPrimaria] = useState('')
    const [corSecundaria, setCorSecundaria] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [instagram, setInstagram] = useState('')
    const [facebook, setFacebook] = useState('')
    const [email, setEmail] = useState('')
    const [nomeCategoria, setNomeCategoria] = useState('')
    const [descricaoCategoria, setDescricaoCategoria] = useState('')

    const [nomeServico, setNomeServico] = useState('')
    const [descricaoServico, setDescricaoServico] = useState('')
    const [valorServico, setValorServico] = useState('')
    const [duracaoServico, setDuracaoServico] = useState('')

    const [nomeContabilidade, setNomeContabilidade] = useState('')
    const [nomeResponsavelContabilidade, setNomeResponsavelContabilidade] = useState('')
    const [cpfCnpjContabilidade, setCpfCnpjContabilidade] = useState('')
    const [emailContabilidade, setEmailContabilidade] = useState('')
    const [telefoneContabilidade, setTelefoneContabilidade] = useState('')
    const [cepContabilidade, setCepContabilidade] = useState('')
    const [complementoContabilidade, setComplementoContabilidade] = useState('')
    const [numeroContabilidade, setNumeroContabilidade] = useState('')
    const [envioAutomaticoContabilidade, setEnvioAutomaticoContabilidade] = useState('')



    const [errorHoraAbertura, setErrorHoraAbertura] = useState(false)
    const [errorHoraFechamento, setErrorHoraFechamento] = useState(false)

    const [errorCorPrimaria, setErrorCorPrimaria] = useState(false)
    const [errorCorPrimariaMsg, setErrorCorPrimariaMsg] = useState('Campo obrigatório.')
    const [errorCorSecundaria, setErrorCorSecundaria] = useState(false)
    const [errorCorSecundariaMsg, setErrorCorSecundariaMsg] = useState('Campo obrigatório.')

    const [errorWhatsapp, setErrorWhatsapp] = useState(false)
    const [errorInstagram, setErrorInstagram] = useState(false)
    const [errorFacebook, setErrorFacebook] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)

    const [errorCategoria, setErrorCategoria] = useState(false)
    const [errorDescricaoCategoria, setErrorDescricaoCategoria] = useState(false)

    const [errorNomeServico, setErrorNomeServico] = useState(false)
    const [errorDescricaoServico, setErrorDescricaoServico] = useState(false)
    const [errorValorServico, setErrorValorServico] = useState(false)
    const [errorDuracaoServico, setErrorDuracaoServico] = useState(false)

    const [errorNomeContabilidade, setErrorNomeContabilidade] = useState(false)
    const [errorCpfCnpjContabilidade, setErrorCpfCnpjContabilidade] = useState(false)
    const [errorEmailContabilidade, setErrorEmailContabilidade] = useState(false)
    const [errorTelefoneContabilidade, setErrorTelefoneContabilidade] = useState(false)
    const [errorCepContabilidade, setErrorCepContabilidade] = useState(false)
    const [errorComplementoContabilidade, setErrorComplementoContabilidade] = useState(false)
    const [errorNumeroContabilidade, setErrorNumeroContabilidade] = useState(false)
    const [errorNomeResponsavelContabilidade, setErrorNomeResponsavelContabilidade] = useState(false)




    const primeiroLoad = async () => {
        await checkLogin()
    }

    const logOut = () => {
        if (states.modalConfiguracao) {
            setters.setModalConfiguracao(false)
        }
        localStorage.removeItem('token')
        localStorage.removeItem('permission')
        localStorage.removeItem('nome')
        localStorage.removeItem('haveCategoria')
        localStorage.removeItem('haveConfig')
        localStorage.removeItem('havePrestador')
        localStorage.removeItem('empresa')
        localStorage.removeItem('usuario')
        localStorage.removeItem('permission')
        setters.setSigned(false)
        navigate(`/plataforma/${token}`)
    }

    const loadConfig = async () => {
        const response = await checkConfigCliente(token)
        if (response === true) {
            localStorage.setItem('haveConfig', 'true')
            setStep1(true)
        } else {
            localStorage.setItem('haveConfig', 'false')
            setStep1(false)
        }

    }

    const loadConfigCategoriaServico = async () => {
        const response = await getConfigCategoriaServico(token)
        if (response === true) {
            localStorage.setItem('haveCategoria', 'true')
            localStorage.setItem('haveServicos', 'true')
            setStep2(true)
        } else {
            localStorage.setItem('haveServicos', 'false')
            localStorage.setItem('haveCategoria', 'false')
            setStep2(false)
        }
    }

    const loadConfigContabilidade = async () => {
        const response = await getConfigContabilidade(token)
        if (response === true) {
            localStorage.setItem('haveContabilidadeConfig', 'true')
            setStep3(true)
        } else {
            localStorage.setItem('haveContabilidadeConfig', 'false')
            setStep3(false)
        }
    }

    const handleColorPrimariaChange = (e) => {
        const value = e.target.value;
        // Verifica se o valor é um hexadecimal válido
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            setErrorCorPrimaria(false);
            setErrorCorPrimariaMsg('Campo obrigatório.');
            setCorPrimaria(value);
        } else {
            setErrorCorPrimariaMsg('Cor inválida. Por favor, insira um hexadecimal válido.');
            setErrorCorPrimaria(true);
        }
    };

    const handleColorSecundariaChange = (e) => {
        const value = e.target.value;
        // Verifica se o valor é um hexadecimal válido
        if (/^#[0-9A-F]{6}$/i.test(value)) {
            setErrorCorSecundaria(false);
            setErrorCorSecundariaMsg('Campo obrigatório.');
            setCorSecundaria(value);
        } else {
            setErrorCorSecundariaMsg('Cor inválida. Por favor, insira um hexadecimal válido.');
            setErrorCorSecundaria(true);
        }
    };

    const formatPhoneBrasil = (phone) => {
        let phoneFormated = phone?.replace(/\D/g, '');
        phoneFormated = phoneFormated?.replace(/^(\d{2})(\d)/g, '($1) $2');
        phoneFormated = phoneFormated?.replace(/(\d)(\d{4})$/, '$1-$2');
        return phoneFormated;
    }

    const formatCep = (cep) => {
        let cepFormated = cep?.replace(/\D/g, '');
        cepFormated = cepFormated?.replace(/^(\d{5})(\d)/g, '$1-$2');
        return cepFormated;
    }


    const action = async () => {
        let error = false;
        if (!step1 && !step2 && !step3 && !step4) {
            if (logo === '') {
                error = true
                setErrorLogo(true)
                setErrorLogoMessage('Campo obrigatório.')
            } else {
                setErrorLogo(false)
                setErrorLogoMessage('Campo obrigatório.')
            }
            if (horarioAbertura === '') {
                error = true
                setErrorHoraAbertura(true)
            } else setErrorHoraAbertura(false)
            if (horarioFechamento === '') {
                error = true
                setErrorHoraFechamento(true)
            } else setErrorHoraFechamento(false)
            if (corPrimaria === '') {
                error = true
                setErrorCorPrimaria(true)
                setErrorCorPrimariaMsg('Campo obrigatório.')
            } else setErrorCorPrimaria(false)
            if (corSecundaria === '') {
                error = true
                setErrorCorSecundaria(true)
                setErrorCorSecundariaMsg('Campo obrigatório.')
            } else setErrorCorSecundaria(false)
            if (whatsapp === '') {
                error = true
                setErrorWhatsapp(true)
            } else setErrorWhatsapp(false)
            if (instagram === '') {
                error = true
                setErrorInstagram(true)
            } else setErrorInstagram(false)
            if (facebook === '') {
                error = true
                setErrorFacebook(true)
            } else setErrorFacebook(false)
            if (email === '') {
                error = true
                setErrorEmail(true)
            } else setErrorEmail(false)

            if (error) {
                error = false
                return;
            }
            const form = new FormData()
            form.append('image', logo)
            form.append('horarioAbertura', horarioAbertura + ':00')
            form.append('horarioFechamento', horarioFechamento + ':00')
            form.append('corPrimaria', corPrimaria)
            form.append('corSecundaria', corSecundaria)
            form.append('whatsapp', whatsapp)
            form.append('instagram', instagram)
            form.append('facebook', facebook)
            form.append('email', email)
            //post aqui
            const response = await postConfigCliente(form, token)
            if (response === true) {
                //Aqui deve limpar as configuracoes
                localStorage.setItem('haveConfig', 'true')
                setWidth(stepValue)
                setStep1(true)
                loadConfig()
            }
        }
        if (step1 && !step2 && !step3 && !step4) {
            if (nomeCategoria === '') {
                error = true
                setErrorCategoria(true)
            } else setErrorCategoria(false)
            if (descricaoCategoria === '') {
                error = true
                setErrorDescricaoCategoria(true)
            } else setErrorDescricaoCategoria(false)

            if (nomeServico === '') {
                error = true
                setErrorNomeServico(true)
            } else setErrorNomeServico(false)
            if (descricaoServico === '') {
                error = true
                setErrorDescricaoServico(true)
            } else setErrorDescricaoServico(false)
            if (valorServico === '') {
                error = true
                setErrorValorServico(true)
            } else setErrorValorServico(false)
            if (duracaoServico === '') {
                error = true
                setErrorDuracaoServico(true)
            } else setErrorDuracaoServico(false)

            if (error) {
                error = false
                return;
            }

            const obj = {
                nomeCategoria,
                descricaoCategoria,
                nomeServico,
                descricaoServico,
                valorServico: valorServico.replace(/\D/g, '') * 100,
                duracaoServico
            }
            const response = await postConfigCategoriaServico(obj, token)
            if (response === true) {
                localStorage.setItem('haveCategoria', 'true')
                localStorage.setItem('haveServicos', 'true')
                setWidth(stepValue * 2)
                setStep2(true)
            }
        }
        if (step1 && step2 && !step3 && !step4) {
            if (nomeContabilidade === '') {
                error = true
                setErrorNomeContabilidade(true)
            } else setErrorNomeContabilidade(false)
            if (cpfCnpjContabilidade === '') {
                error = true
                setErrorCpfCnpjContabilidade(true)
            } else setErrorCpfCnpjContabilidade(false)
            if (emailContabilidade === '') {
                error = true
                setErrorEmailContabilidade(true)
            } else setErrorEmailContabilidade(false)
            if (telefoneContabilidade === '') {
                error = true
                setErrorTelefoneContabilidade(true)
            } else setErrorTelefoneContabilidade(false)
            if (cepContabilidade === '') {
                error = true
                setErrorCepContabilidade(true)
            } else setErrorCepContabilidade(false)
            if (complementoContabilidade === '') {
                error = true
                setErrorComplementoContabilidade(true)
            } else setErrorComplementoContabilidade(false)
            if (numeroContabilidade === '') {
                error = true
                setErrorNumeroContabilidade(true)
            } else setErrorNumeroContabilidade(false)

            if (envioAutomaticoContabilidade === '') {
                setEnvioAutomaticoContabilidade(false)
            }

            if (nomeResponsavelContabilidade === '') {
                error = true
                setErrorNomeResponsavelContabilidade(true)
            } else setErrorNomeResponsavelContabilidade(false)

            if (error) {
                error = false
                return;
            }

            const obj = {
                nome_empresa: nomeContabilidade,
                nome: nomeResponsavelContabilidade,
                cpf_cnpj: cpfCnpjContabilidade,
                email: emailContabilidade,
                telefone: telefoneContabilidade,
                cep: cepContabilidade,
                complemento: complementoContabilidade,
                numero: numeroContabilidade,
                envio_automatico: envioAutomaticoContabilidade === true ? 'true' : 'false'
            }
            const response = await postConfigContabilidade(obj, token)
            if (response === true) {
                localStorage.setItem('haveContabilidadeConfig', 'true')
                setWidth(stepValue * 3)
                setStep3(true)
                await loadConfigCategoriaServico()
                setters.setModalConfiguracao(true)
            }

        }
    }

    const editStep1Button = async () => {
        setEditStep1(!editStep1)
    }

    const cancelReturnStep = async () => {
        setEditStep1(false)
        if (Object.keys(states.clienteConfig).length > 0) {
            setConfigCliente(states.clienteConfig)
        } else {
            const js = JSON.parse(localStorage.getItem('configCliente'))
            setConfigCliente(js)
            setters.setClienteConfig(js)
        }

    }

    const actionEditStep1 = async () => {
        let error = false;
        if (configCliente.horarioAbertura === '') {
            error = true
            setErrorHoraAbertura(true)
        } else setErrorHoraAbertura(false)
        if (configCliente.horarioFechamento === '') {
            error = true
            setErrorHoraFechamento(true)
        } else setErrorHoraFechamento(false)
        if (configCliente.corPrimaria === '') {
            error = true
            setErrorCorPrimaria(true)
            setErrorCorPrimariaMsg('Campo obrigatório.')
        } else setErrorCorPrimaria(false)
        if (configCliente.corSecundaria === '') {
            error = true
            setErrorCorSecundaria(true)
            setErrorCorSecundariaMsg('Campo obrigatório.')
        } else setErrorCorSecundaria(false)
        if (configCliente.whatsapp === '') {
            error = true
            setErrorWhatsapp(true)
        } else setErrorWhatsapp(false)
        if (configCliente.instagram === '') {
            error = true
            setErrorInstagram(true)
        } else setErrorInstagram(false)
        if (configCliente.facebook === '') {
            error = true
            setErrorFacebook(true)
        } else setErrorFacebook(false)
        if (configCliente.email === '') {
            error = true
            setErrorEmail(true)
        } else setErrorEmail(false)

        if (error) {
            error = false
            return;
        }
        const resp = await putConfigCliente(configCliente, token)
        if (resp) {
            setEditStep1(false)
            setStep1(true)
        }
    }

    const formatToMoney = value => {
        if (!value) return
        const valorFloat = value / 100;
        return valorFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    const formatCpfCnpj = value => {
        if (!value) return
        if (value.length <= 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
    }

    const actionModal = async (data, myprofile) => {
        if (myprofile) {
            const resp = await postConfigPrestadorMyProfile(data, token)
            if (resp) {
                setters.setModalConfiguracao(false)
                navigate(`/plataforma/${token}`)
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
            } else {
                setters.setModalConfiguracao(true)
            }
            return;
        } else {
            const resp = await postPrestadorNewProfile(data, token)
            if (resp) {
                navigate(`/dashboard/${token}`)
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
            } else {
                navigate(`/plataforma/${token}`)
            }

        }

    }


    useEffect(() => {
        primeiroLoad()
        const haveConfig = localStorage.getItem('haveConfig')
        const haveCategoria = localStorage.getItem('haveCategoria')
        const havePrestador = localStorage.getItem('havePrestador')
        const haveContabilidadeConfig = localStorage.getItem('haveContabilidadeConfig')
        let percentUp = 0;
        if (haveConfig === null || haveCategoria === null || havePrestador === null) {
            navigate(`/plataforma/${token}`)
        }
        if (haveConfig === 'true') {
            percentUp = percentUp + stepValue
            setStep1(true)
        }
        else setStep1(false)
        if (haveCategoria === 'true') {
            percentUp = percentUp + stepValue
            setStep2(true)
        }
        else setStep2(false)
        if (haveContabilidadeConfig === 'true') {
            percentUp = percentUp + stepValue
            setStep3(true)
        }
        else setStep3(false)

        if (havePrestador === 'true') {
            percentUp = percentUp + stepValue
            setStep4(true)
        }
        else setStep4(false)

        setWidth((percentUp / 100) * 100)

        if (haveConfig === 'true') {
            loadConfig()
        }
        if (haveCategoria === 'true') {
            loadConfigCategoriaServico()
        }
        if (haveContabilidadeConfig === 'true') {
            loadConfigContabilidade()
        }
        if (haveContabilidadeConfig === 'true' && haveCategoria === 'true' && haveConfig === 'true') {
            setters.setModalConfiguracao(true)
        }
        if (localStorage.getItem('empresa') === 'true') {
            setEmpresa(true)
        } else {
            setEmpresa(false)
        }
        setNome(localStorage.getItem('nome'))
        setEditStep1(false)

        return () => {
            //Deletar aqui todos os localStorage que tem armazenados -> NÃO ESQUECER



        }

    }, [])


    //deixar os states no 0 de acordo com o que se tem configurado
    useEffect(() => {
        if (states.clientePossuiConfig === true) {
            // setLogo(states.clienteConfig.logo)
            // setHorarioAbertura(states.clienteConfig.horarioAbertura)
            // setHorarioFechamento(states.clienteConfig.horarioFechamento)
            // setCorPrimaria(states.clienteConfig.corPrimaria)
            // setCorSecundaria(states.clienteConfig.corSecundaria)
            // setWhatsapp(states.clienteConfig.whatsapp)
            // setInstagram(states.clienteConfig.instagram)
            // setFacebook(states.clienteConfig.facebook)
            // setEmail(states.clienteConfig.email)
            setConfigCliente(states.clienteConfig)
        }
    }, [states.clienteConfig])

    useEffect(() => {
        if (editStep1 === false && step1) {
            setErrorCorPrimaria(false)
            setErrorCorSecundaria(false)
            setErrorHoraAbertura(false)
            setErrorHoraFechamento(false)
            setErrorWhatsapp(false)
            setErrorInstagram(false)
            setErrorFacebook(false)
            setErrorEmail(false)
        }
    }, [editStep1])


    return (
        <>
            <div className="flex border rounded-[10px] p-4 flex-col boder mt-4">

                <div className="flex flex-col sm:flex-row border p-4 rounded-[10px] hover:shadow">
                    <div className='flex w-full flex-col  items-center justify-center  '>
                        {
                            states.clientePossuiConfig === false ? (<>
                                <img
                                    className="h-[8em] w-auto"
                                    src={`${jsonConfig.urlReact}/logo.png`}
                                    alt="Soter Tec Logo"
                                />
                            </>) : (<>
                                <img
                                    className="h-[5em] w-auto"
                                    src={`data:image/png;base64,${states.clienteConfig.logo}`}
                                    alt={`Logo ${states.clienteConfig.cliente_nome}`}
                                />
                            </>)
                        }
                    </div>
                    <div className='flex w-full flex-col  items-center justify-center  '>
                        <h1 className="text-2xl font-bold t">Configurações</h1>
                        <p className="text-sm ml-4 mt-4">Olá, {nome}. Complete as configurações do seu sistema!</p>
                    </div>
                    <div className='flex w-full flex-col sm:flex-row  items-center justify-center  '>
                        {/* Botao para sair e botao para finalizar */}
                        <button
                            onClick={logOut}
                            className="bg-red-500 rounded p-2 px-7 text-white hover:bg-red-700 sm:mt-0 mt-4 sm:ml-4 ml-0"
                        >
                            Sair
                        </button>
                        {
                            (!step1 || !step2 || !step3) && (<>
                                <button
                                    onClick={action}
                                    className="bg-green-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-0 hover:bg-green-700"
                                >
                                    Continuar
                                </button>
                            </>)
                        }
                    </div>
                </div>
                <div className="w-full bg-gray-200 h-5 rounded mt-8 relative">
                    <div
                        style={{ width: `${width}%` }}
                        className={`bg-blue-500 h-5 rounded flex items-center justify-center ${width > 50 ? 'text-white' : 'text-black'
                            }`}
                    >
                        <p className='ml-12'>{width.toFixed(0)}%</p>
                    </div>
                </div>

                {
                    (step1 && Object.keys(configCliente).length > 0) && (<>
                        <div className='flex mt-4'>
                            <p className="text-sm ml-4 mt-4">Configurações atuais:</p>
                            <button onClick={editStep1Button} className="bg-blue-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-2 hover:bg-blue-700">
                                <PencilIcon className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4 mt-4 border p-5 rounded-[10px] hover:shadow">
                            <div className="sm:col-span-1">
                                <label htmlFor="horario_abertura" className="block text-sm font-semibold leading-5 text-gray-700">Horário de abertura</label>
                                <input
                                    id="horario_abertura"
                                    name="horario_abertura"
                                    type="time"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={configCliente.horarioAbertura}
                                    onChange={(e) => setConfigCliente({ ...configCliente, horarioAbertura: e.target.value === '' ? '' : e.target.value + ':00' })}
                                    disabled={editStep1 === true ? false : true}
                                />
                                {
                                    errorHoraAbertura && (<>
                                        <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                    </>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="horario_fechamento" className="block text-sm font-semibold leading-5 text-gray-700">Horário de fechamento</label>
                                <input
                                    id="horario_fechamento"
                                    name="horario_fechamento"
                                    type="time"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={configCliente.horarioFechamento}
                                    onChange={(e) => setConfigCliente({ ...configCliente, horarioFechamento: e.target.value === '' ? '' : `${e.target.value}:00` })}
                                    disabled={editStep1 === true ? false : true}
                                />
                                {
                                    errorHoraFechamento && (<>
                                        <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                    </>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="cor_primaria" className="block text-sm font-semibold leading-5 text-gray-700">Cor primária</label>
                                <div className="flex items-center">
                                    <input
                                        id="cor_primaria"
                                        name="cor_primaria"
                                        type="color"
                                        className="mt-1 block w-12 h-12 border border-gray-500  cursor-pointer"
                                        value={configCliente.corPrimaria}
                                        onChange={e => {
                                            const value = e.target.value;
                                            // Verifica se o valor é um hexadecimal válido
                                            if (/^#[0-9A-F]{6}$/i.test(value)) {
                                                setErrorCorPrimaria(false);
                                                setErrorCorPrimariaMsg('Campo obrigatório.');
                                                setConfigCliente({ ...configCliente, corPrimaria: value });
                                            } else {
                                                setErrorCorPrimariaMsg('Cor inválida. Por favor, insira um hexadecimal válido.');
                                                setErrorCorPrimaria(true);
                                            }
                                        }}
                                        disabled={editStep1 === true ? false : true}
                                    />
                                    <input
                                        type="text"
                                        className={`ml-3 block w-full  pl-3 pr-10 py-2 text-base border ${errorCorPrimaria ? 'border-red-500' : 'border-gray-500'
                                            } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                        value={configCliente.corPrimaria}
                                        disabled={editStep1 === true ? false : true}
                                        onChange={e => {
                                            const value = e.target.value;
                                            // Verifica se o valor é um hexadecimal válido
                                            if (/^#[0-9A-F]{6}$/i.test(value)) {
                                                setErrorCorPrimaria(false);
                                                setErrorCorPrimariaMsg('Campo obrigatório.');
                                                setConfigCliente({ ...configCliente, corPrimaria: value });
                                            } else {
                                                setErrorCorPrimariaMsg('Cor inválida. Por favor, insira um hexadecimal válido.');
                                                setErrorCorPrimaria(true);
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    errorCorPrimaria && (<>
                                        <p className="text-red-500 text-sm mt-3 ml-2">{errorCorPrimariaMsg}</p>
                                    </>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="cor_secundaria" className="block text-sm font-semibold leading-5 text-gray-700">Cor secundária</label>
                                <div className="flex items-center">
                                    <input
                                        id="cor_secundaria"
                                        name="cor_secundaria"
                                        type="color"
                                        className="mt-1 block w-12 h-12 border border-gray-500  cursor-pointer"
                                        value={configCliente.corSecundaria}
                                        disabled={editStep1 === true ? false : true}
                                        onChange={e => {
                                            const value = e.target.value;
                                            // Verifica se o valor é um hexadecimal válido
                                            if (/^#[0-9A-F]{6}$/i.test(value)) {
                                                setErrorCorSecundaria(false);
                                                setErrorCorSecundariaMsg('Campo obrigatório.');
                                                setConfigCliente({ ...configCliente, corSecundaria: value });
                                            } else {
                                                setErrorCorSecundariaMsg('Cor inválida. Por favor, insira um hexadecimal válido.');
                                                setErrorCorSecundaria(true);
                                            }
                                        }}
                                    />
                                    <input
                                        type="text"
                                        className={`ml-3 block w-full  pl-3 pr-10 py-2 text-base border ${errorCorPrimaria ? 'border-red-500' : 'border-gray-500'
                                            } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                        value={configCliente.corSecundaria}
                                        disabled={editStep1 === true ? false : true}
                                        onChange={e => {
                                            const value = e.target.value;
                                            // Verifica se o valor é um hexadecimal válido
                                            if (/^#[0-9A-F]{6}$/i.test(value)) {
                                                setErrorCorSecundaria(false);
                                                setErrorCorSecundariaMsg('Campo obrigatório.');
                                                setConfigCliente({ ...configCliente, corSecundaria: value });
                                            } else {
                                                setErrorCorSecundariaMsg('Cor inválida. Por favor, insira um hexadecimal válido.');
                                                setErrorCorSecundaria(true);
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    errorCorSecundaria && (<>
                                        <p className="text-red-500 text-sm mt-3 ml-2">{errorCorSecundariaMsg}</p>
                                    </>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="whatsapp" className="block text-sm font-semibold leading-5 text-gray-700">Whatsapp</label>
                                <input
                                    id="whatsapp"
                                    name="whatsapp"
                                    type="text"
                                    value={formatPhoneBrasil(configCliente.whatsapp)}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    disabled={editStep1 === true ? false : true}
                                    onChange={(e) => {
                                        const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                        if (onlyNums === '') {
                                            setWhatsapp('')
                                            setConfigCliente({ ...configCliente, whatsapp: '' });
                                            setErrorWhatsapp(true)
                                        } else {
                                            setErrorWhatsapp(false)
                                            if (onlyNums.length <= 11) {
                                                setConfigCliente({ ...configCliente, whatsapp: onlyNums });
                                            }
                                        }
                                    }}
                                />
                                {
                                    errorWhatsapp && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="instagram" className="block text-sm font-semibold leading-5 text-gray-700">Instagram</label>
                                <input
                                    id="instagram"
                                    name="instagram"
                                    type="text"
                                    value={configCliente.instagram}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    disabled={editStep1 === true ? false : true}
                                    onChange={(e) => setConfigCliente({ ...configCliente, instagram: e.target.value })}
                                />
                                {
                                    errorInstagram && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="facebook" className="block text-sm font-semibold leading-5 text-gray-700">Facebook</label>
                                <input
                                    id="facebook"
                                    name="facebook"
                                    type="text"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={configCliente.facebook}
                                    disabled={editStep1 === true ? false : true}
                                    onChange={(e) => setConfigCliente({ ...configCliente, facebook: e.target.value })}
                                />
                                {
                                    errorFacebook && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                }
                            </div>
                            <div className="sm:col-span-1">
                                <label htmlFor="email" className="block text-sm font-semibold leading-5 text-gray-700">Email</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={configCliente.email}
                                    disabled={editStep1 === true ? false : true}
                                    onChange={(e) => setConfigCliente({ ...configCliente, email: e.target.value })}
                                />
                                {
                                    errorEmail && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                }
                            </div>
                        </div>
                        {
                            (editStep1 === true) && (<>
                                <div className='flex w-full items-start justify-start mt-4'>
                                    <button onClick={cancelReturnStep} className=" mt-4 bg-red-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-0 hover:bg-red-700">
                                        Cancelar
                                    </button>
                                    <button onClick={actionEditStep1} className=" mt-4 bg-green-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-0 hover:bg-green-700">
                                        Salvar
                                    </button>
                                </div>
                            </>)
                        }

                    </>)
                }
                {
                    ((!step1 && Object.keys(configCliente).length === 0)) && (<>
                        <div className="flex flex-col mt-4">
                            {/* LOGO => APENAS A LOGO AQUI */}
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 border p-4 rounded-[10px] hover:shadow">
                                <div className="sm:col-span-2">
                                    <div className='flex '>
                                        <label htmlFor="logo" className="block text-sm font-semibold leading-5 text-gray-700">Logo</label>
                                        <p className="text-sm text-gray-500 ml-2">Escolha a logo da sua empresa</p>
                                    </div>
                                    <div className='flex flex-col sm:flex-row  items-between justify-between mt-3'>
                                        <UploadLogo setError={setErrorLogo} setErrorFileMessage={setErrorLogoMessage} setFile={setLogo} file={logo} />
                                        <span className="mt-4 sm:mt-0 ml-0 sm:ml-3"></span>
                                        <ShowFile file={logo} />
                                    </div>
                                    {
                                        errorLogo && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">{errorLogoMessage}</p>
                                        </>)
                                    }
                                </div>
                            </div>

                            {/* DE HORARIOS ATE COR SECUNDARIA */}
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-4 border p-5 rounded-[10px] hover:shadow">
                                <div className="sm:col-span-1">
                                    <label htmlFor="horario_abertura" className="block text-sm font-semibold leading-5 text-gray-700">Horário de abertura</label>
                                    <input
                                        id="horario_abertura"
                                        name="horario_abertura"
                                        type="time"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={horarioAbertura}
                                        onChange={(e) => setHorarioAbertura(e.target.value)}
                                    />
                                    {
                                        errorHoraAbertura && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="horario_fechamento" className="block text-sm font-semibold leading-5 text-gray-700">Horário de fechamento</label>
                                    <input
                                        id="horario_fechamento"
                                        name="horario_fechamento"
                                        type="time"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={horarioFechamento}
                                        onChange={(e) => setHorarioFechamento(e.target.value)}
                                    />
                                    {
                                        errorHoraFechamento && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="cor_primaria" className="block text-sm font-semibold leading-5 text-gray-700">Cor primária</label>
                                    <div className="flex items-center">
                                        <input
                                            id="cor_primaria"
                                            name="cor_primaria"
                                            type="color"
                                            className="mt-1 block w-12 h-12 border border-gray-500  cursor-pointer"
                                            value={corPrimaria}
                                            onChange={handleColorPrimariaChange}
                                        />
                                        <input
                                            type="text"
                                            className={`ml-3 block w-full  pl-3 pr-10 py-2 text-base border ${errorCorPrimaria ? 'border-red-500' : 'border-gray-500'
                                                } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                            value={corPrimaria}
                                            onChange={handleColorPrimariaChange}
                                        />
                                    </div>
                                    {
                                        errorCorPrimaria && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">{errorCorPrimariaMsg}</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="cor_secundaria" className="block text-sm font-semibold leading-5 text-gray-700">Cor secundária</label>
                                    <div className="flex items-center">
                                        <input
                                            id="cor_secundaria"
                                            name="cor_secundaria"
                                            type="color"
                                            className="mt-1 block w-12 h-12 border border-gray-500  cursor-pointer"
                                            value={corSecundaria}
                                            onChange={handleColorSecundariaChange}
                                        />
                                        <input
                                            type="text"
                                            className={`ml-3 block w-full  pl-3 pr-10 py-2 text-base border ${errorCorPrimaria ? 'border-red-500' : 'border-gray-500'
                                                } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                            value={corSecundaria}
                                            onChange={handleColorPrimariaChange}
                                        />
                                    </div>
                                    {
                                        errorCorSecundaria && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">{errorCorSecundariaMsg}</p>
                                        </>)
                                    }
                                </div>
                            </div>

                            {/* DE HORARIOS ATE COR SECUNDARIA */}
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-4 border p-5 rounded-[10px] hover:shadow">
                                <div className="sm:col-span-1">
                                    <label htmlFor="whatsapp" className="block text-sm font-semibold leading-5 text-gray-700">Whatsapp</label>
                                    <input
                                        id="whatsapp"
                                        name="whatsapp"
                                        type="text"
                                        value={formatPhoneBrasil(whatsapp)}
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                            if (onlyNums === '') {
                                                setWhatsapp('')
                                                setErrorWhatsapp(true)
                                            } else {
                                                setErrorWhatsapp(false)
                                                if (onlyNums.length <= 11) {
                                                    setWhatsapp(onlyNums);
                                                }
                                            }
                                        }}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    />
                                    {
                                        errorWhatsapp && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="instagram" className="block text-sm font-semibold leading-5 text-gray-700">Instagram</label>
                                    <input
                                        id="instagram"
                                        name="instagram"
                                        type="text"
                                        value={instagram}
                                        onChange={(e) => setInstagram(e.target.value)}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    />
                                    {
                                        errorInstagram && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="facebook" className="block text-sm font-semibold leading-5 text-gray-700">Facebook</label>
                                    <input
                                        id="facebook"
                                        name="facebook"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={facebook}
                                        onChange={(e) => setFacebook(e.target.value)}
                                    />
                                    {
                                        errorFacebook && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="email" className="block text-sm font-semibold leading-5 text-gray-700">Email</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {
                                        errorEmail && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                    }
                                </div>
                            </div>
                            <button onClick={action} className=" mt-4 bg-green-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-0 hover:bg-green-700">
                                {
                                    step1 && step2 && step3 ? 'Finalizar' : 'Continuar'
                                }
                            </button>
                        </div>
                    </>)
                }
                {
                    (step1 && !step2 && !step3 && !step4) && (<>
                        <div className="flex flex-col mt-4">
                            {/* CATEGORIA */}
                            <label className="text-sm ml-4 mt-4">Adicione a primeira categoria de serviço:</label>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 border p-4 rounded-[10px] hover:shadow sm:mt-4">
                                <div className="sm:col-span-1">
                                    <label htmlFor="horario_abertura" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                    <input
                                        id="nome_categoria"
                                        name="nome_categoria"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={nomeCategoria}
                                        onChange={(e) => setNomeCategoria(e.target.value)}
                                    />
                                    {
                                        errorCategoria && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="horario_fechamento" className="block text-sm font-semibold leading-5 text-gray-700">Descrição da categoria</label>
                                    <textarea
                                        id="descricao_categoria"
                                        name="descricao_categoria"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={descricaoCategoria}
                                        onChange={(e) => setDescricaoCategoria(e.target.value)}
                                    />
                                    {
                                        errorDescricaoCategoria && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                            </div>
                            {/* SERVIÇO */}
                            <label className="text-sm ml-4 mt-4">Adicione seu primeira serviço:</label>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4 border p-4 rounded-[10px] hover:shadow sm:mt-4">
                                {/* Valor, duracao, nome e descricao */}
                                <div className="sm:col-span-1">
                                    <label htmlFor="valor" className="block text-sm font-semibold leading-5 text-gray-700">Valor</label>
                                    <input
                                        id="valor"
                                        name="valor"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatToMoney(valorServico)}
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                            if (onlyNums === '') {
                                                setValorServico('')
                                                setErrorValorServico(true)
                                            } else {
                                                setErrorValorServico(false)
                                                setValorServico(onlyNums);
                                            }
                                        }}
                                    />
                                    {
                                        errorValorServico && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="duracao" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                    <input
                                        id="duracao"
                                        name="duracao"
                                        type="time"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={duracaoServico}
                                        onChange={(e) => setDuracaoServico(e.target.value)}
                                    />
                                    {
                                        errorDuracaoServico && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_servico" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                    <input
                                        id="nome_servico"
                                        name="nome_servico"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={nomeServico}
                                        onChange={(e) => setNomeServico(e.target.value)}
                                    />
                                    {
                                        errorNomeServico && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="descricao_servico" className="block text-sm font-semibold leading-5 text-gray-700">Descrição do serviço</label>
                                    <textarea
                                        id="descricao_servico"
                                        name="descricao_servico"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={descricaoServico}
                                        onChange={(e) => setDescricaoServico(e.target.value)}
                                    />
                                    {
                                        errorDescricaoServico && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>

                            </div>

                            <div className='flex w-full items-start justify-start mt-4'>
                                <button onClick={action} className=" mt-4 bg-green-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-0 hover:bg-green-700">
                                    {
                                        step1 && step2 && step3 && step4 ? 'Finalizar' : 'Continuar'
                                    }
                                </button>
                            </div>
                        </div>
                    </>)
                }
                {
                    (step1 && step2 && Object.keys(states.categoriaServico).length > 0) && (<>
                        <div className="flex flex-col mt-4">
                            {/* CATEGORIA */}
                            <label className="text-sm ml-4 mt-4">Categoria e serviço cadastrado: </label>

                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 border p-4 rounded-[10px] hover:shadow sm:mt-4">
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_categoria" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                    <input
                                        id="nome_categoria"
                                        name="nome_categoria"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.categoriaServico?.categoria?.nome}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="descricao_categoria" className="block text-sm font-semibold leading-5 text-gray-700">Descrição da categoria</label>
                                    <textarea
                                        id="descricao_categoria"
                                        name="descricao_categoria"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.categoriaServico?.categoria?.descricao}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 border p-4 rounded-[10px] hover:shadow sm:mt-4">
                                <div className="sm:col-span-1">
                                    <label htmlFor="valor" className="block text-sm font-semibold leading-5 text-gray-700">Valor</label>
                                    <input
                                        id="valor"
                                        name="valor"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatToMoney(states.categoriaServico?.servico?.preco)}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="duracao" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                    <input
                                        id="duracao"
                                        name="duracao"
                                        type="time"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.categoriaServico?.servico?.duracao}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_servico" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                    <input
                                        id="nome_servico"
                                        name="nome_servico"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.categoriaServico?.servico?.nome}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="descricao_servico" className="block text-sm font-semibold leading-5 text-gray-700">Descrição do serviço</label>
                                    <textarea
                                        id="descricao_servico"
                                        name="descricao_servico"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-ind
                                        igo-500 sm:text-sm rounded-md"
                                        value={states.categoriaServico?.servico?.descricao}
                                        disabled
                                    />
                                </div>
                            </div>


                        </div>

                    </>)
                }
                {
                    (step1 && step2 && !step3 && !step4) && (<>
                        <div className="flex flex-col mt-4">
                            <label className="text-sm ml-4 mt-4">Adicione suas configurações de contabilidade:</label>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4 border p-4 rounded-[10px] hover:shadow sm:mt-4">
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                    <input
                                        id="nome_contabilidade"
                                        name="nome_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={nomeContabilidade}
                                        onChange={(e) => setNomeContabilidade(e.target.value)}
                                    />
                                    {
                                        errorNomeContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Nome do contador</label>
                                    <input
                                        id="nome_contabilidade"
                                        name="nome_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={nomeResponsavelContabilidade}
                                        onChange={(e) => setNomeResponsavelContabilidade(e.target.value)}
                                    />
                                    {
                                        errorNomeResponsavelContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="cpf_cnpj_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">CPF/CNPJ</label>
                                    <input
                                        id="cpf_cnpj_contabilidade"
                                        name="cpf_cnpj_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatCpfCnpj(cpfCnpjContabilidade)}
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                            if (onlyNums === '') {
                                                setCpfCnpjContabilidade('')
                                                setErrorCpfCnpjContabilidade(true)
                                            } else {
                                                setErrorCpfCnpjContabilidade(false)
                                                if (onlyNums.length <= 14) {
                                                    setCpfCnpjContabilidade(onlyNums);
                                                }
                                            }
                                        }}
                                    />
                                    {
                                        errorCpfCnpjContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="email_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Email</label>
                                    <input
                                        id="email_contabilidade"
                                        name="email_contabilidade"
                                        type="email"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={emailContabilidade}
                                        onChange={(e) => setEmailContabilidade(e.target.value)}
                                    />
                                    {
                                        errorEmailContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="telefone_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                    <input
                                        id="telefone_contabilidade"
                                        name="telefone_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatPhoneBrasil(telefoneContabilidade)}
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                            if (onlyNums === '') {
                                                setTelefoneContabilidade('')
                                                setErrorTelefoneContabilidade(true)
                                            } else {
                                                setErrorTelefoneContabilidade(false)
                                                if (onlyNums.length <= 11) {
                                                    setTelefoneContabilidade(onlyNums);
                                                }
                                            }
                                        }}
                                    />
                                    {
                                        errorTelefoneContabilidade && (<span className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</span>)
                                    }
                                </div>

                                <div className="sm:col-span-1">
                                    <label htmlFor="cep_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">CEP</label>
                                    <input
                                        id="cep_contabilidade"
                                        name="cep_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatCep(cepContabilidade)}
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                            if (onlyNums === '') {
                                                setCepContabilidade('')
                                                setErrorCepContabilidade(true)
                                            } else {
                                                setErrorCepContabilidade(false)
                                                if (onlyNums.length <= 8) {
                                                    setCepContabilidade(onlyNums);
                                                }
                                            }
                                        }}
                                    />
                                    {
                                        errorCepContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="numero_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Número</label>
                                    <input
                                        id="numero_contabilidade"
                                        name="numero_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={numeroContabilidade}
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/[^\d]/g, '');
                                            if (onlyNums === '') {
                                                setNumeroContabilidade('')
                                                setErrorNumeroContabilidade(true)
                                            } else {
                                                setErrorNumeroContabilidade(false)
                                                setNumeroContabilidade(onlyNums);
                                            }
                                        }}
                                    />
                                    {
                                        errorNumeroContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="complemento_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                    <input
                                        id="complemento_contabilidade"
                                        name="complemento_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={complementoContabilidade}
                                        onChange={(e) => setComplementoContabilidade(e.target.value)}
                                    />
                                    {
                                        errorComplementoContabilidade && (<>
                                            <p className="text-red-500 text-sm mt-3 ml-2">Campo obrigatório.</p>
                                        </>)
                                    }
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="envio_automatico_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Envio automático do carnê leão</label>
                                    <input
                                        id="envio_automatico_contabilidade"
                                        name="envio_automatico_contabilidade"
                                        type="checkbox"
                                        className="mt-1 block w-6 h-6 border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        checked={envioAutomaticoContabilidade}
                                        onChange={(e) => setEnvioAutomaticoContabilidade(e.target.checked)}
                                    />
                                </div>

                            </div>

                            <div className='flex w-full items-start justify-start mt-4'>
                                <button onClick={action} className=" mt-4 bg-green-500 rounded p-2 px-4 text-white ml-0 sm:ml-4 mt-4 sm:mt-0 hover:bg-green-700">
                                    {
                                        step1 && step2 && step3 && step4 ? 'Finalizar' : 'Continuar'
                                    }
                                </button>
                            </div>
                        </div>
                    </>)
                }
                {
                    (step1 && step2 && step3 && Object.keys(states.configuracaoContabilidade).length > 0) && (<>
                        <div className="flex flex-col mt-4">
                            {/* CATEGORIA */}
                            <label className="text-sm ml-4 mt-4">Configuração de contabilidade</label>

                            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4 lg:grid-cols- border p-4 rounded-[10px] hover:shadow sm:mt-4">
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                    <input
                                        id="nome_contabilidade"
                                        name="nome_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.configuracaoContabilidade?.nome_empresa}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="nome_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Nome do contador</label>
                                    <input
                                        id="nome_contabilidade"
                                        name="nome_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.configuracaoContabilidade?.nome}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="cpf_cnpj_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">CPF/CNPJ</label>
                                    <input
                                        id="cpf_cnpj_contabilidade"
                                        name="cpf_cnpj_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatCpfCnpj(states.configuracaoContabilidade?.cpf_cnpj)}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="email_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Email</label>
                                    <input
                                        id="email_contabilidade"
                                        name="email_contabilidade"
                                        type="email"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.configuracaoContabilidade?.email}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="telefone_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                    <input
                                        id="telefone_contabilidade"
                                        name="telefone_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatPhoneBrasil(states.configuracaoContabilidade?.telefone)}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="cep_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">CEP</label>
                                    <input
                                        id="cep_contabilidade"
                                        name="cep_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={formatCep(states.configuracaoContabilidade?.cep)}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="numero_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Endereço</label>
                                    <input
                                        id="numero_contabilidade"
                                        name="numero_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={`${states.configuracaoContabilidade?.endereco}, ${states.configuracaoContabilidade?.numero} - ${states.configuracaoContabilidade?.bairro}`}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="numero_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                    <input
                                        id="numero_contabilidade"
                                        name="numero_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.configuracaoContabilidade?.complemento}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="numero_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Cidade</label>
                                    <input
                                        id="numero_contabilidade"
                                        name="numero_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.configuracaoContabilidade?.cidade}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="numero_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Estado</label>
                                    <input
                                        id="numero_contabilidade"
                                        name="numero_contabilidade"
                                        type="text"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={states.configuracaoContabilidade?.estado}
                                        disabled
                                    />
                                </div>
                                <div className="sm:col-span-1">
                                    <label htmlFor="envio_automatico_contabilidade" className="block text-sm font-semibold leading-5 text-gray-700">Envio automático do carnê leão</label>
                                    <input
                                        id="envio_automatico_contabilidade"
                                        name="envio_automatico_contabilidade"
                                        type="checkbox"
                                        className="mt-1 block w-6 h-6 border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        checked={states.configuracaoContabilidade?.envioAutomatico === 1 ? true : false}
                                        disabled
                                    />
                                </div>
                            </div>



                        </div>

                    </>)
                }
            </div>
            <ModalConfiguracao states={states} setters={setters} out={logOut} empresa={empresa} action={actionModal} />
        </>
    )

}



export default CompletarConfiguracao;