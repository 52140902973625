import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const ModalClientes = ({ states, setters, action }) => {



    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [cep, setCep] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [telefone, setTelefone] = useState('')

    const [errorNome, setErrorNome] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorNumero, setErrorNumero] = useState(false)
    const [errorComplemento, setErrorComplemento] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)


    const formatTelefone = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
        } else {
            return value?.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
        }
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const handleSave = () => {
        let error = false
        if (nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)

        if (email === '') {
            setErrorEmail(true)
            error = true
        } else setErrorEmail(false)

        if (cpf === '') {
            setErrorCpf(true)
            error = true
        } else setErrorCpf(false)

        if (cep === '') {
            setErrorCep(true)
            error = true
        } else setErrorCep(false)

        if (numero === '') {
            setErrorNumero(true)
            error = true
        } else setErrorNumero(false)

        if (complemento === '') {
            setErrorComplemento(true)
            error = true
        } else setErrorComplemento(false)

        if (telefone === '') {
            setErrorTelefone(true)
            error = true
        } else setErrorTelefone(false)

        if (error) {
            error = false
            return
        }

        let obj = {};
        if (!states.isEdit) {
            obj = {
                nome,
                email,
                cpf,
                cep,
                numero,
                complemento,
                telefone,
                role: '6'
            }
        } else {
            obj = {
                nome,
                email,
                cpf,
                cep,
                numero,
                complemento,
                telefone
            }
        }
        action(obj)


    }





    useEffect(() => {
        if (states.modalUsuarios === false) {
            setters.setUsuarios({})
            setters.setIsEdit(false)
            setNome('')
            setEmail('')
            setCpf('')
            setCep('')
            setNumero('')
            setComplemento('')
            setTelefone('')
            setErrorNome(false)
            setErrorEmail(false)
            setErrorCpf(false)
            setErrorCep(false)
            setErrorNumero(false)
            setErrorComplemento(false)
            setErrorTelefone(false)
        } else {
            //REGRAS DE EDICAO
            if (states.isEdit) {
                setNome(states.Usuarios.nome)
                setEmail(states.Usuarios.email)
                setCpf(states.Usuarios.cpf)
                setCep(states.Usuarios.cep)
                setNumero(states.Usuarios.numero)
                setComplemento(states.Usuarios.complemento)
                setTelefone(states.Usuarios.telefone)
            }
        }

    }, [states.modalUsuarios])





    return (
        <Transition.Root show={states.modalUsuarios} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setters.setModalUsuarios(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setters.setModalUsuarios(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Usuario</h3>
                                    <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">
                                            {
                                                states.isEdit ? 'Edite os campos abaixo para alterar o usuário.' : 'Preencha os campos abaixo para cadastrar um novo usuário.'
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className='pt-10 pl-10 pr-10'>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do usuário.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={nome}
                                                            onChange={(e) => setNome(e.target.value)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorNome && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                        <input
                                                            type="email"
                                                            id="title"
                                                            name="title"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorEmail && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cpf</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={formatToCpfOrCnpj(cpf)}
                                                            maxLength={14}
                                                            onChange={(e) => setCpf(e.target.value.replace(/\D/g, ''))}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorCpf && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3 mt-4">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cep</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={formatCep(cep)}
                                                            maxLength={9}
                                                            onChange={(e) => {
                                                                const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                                                setCep(onlyNumbers)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorCep && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Número</label>
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={numero}
                                                            onChange={(e) => {
                                                                const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                                                setNumero(onlyNumbers)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorNumero && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={complemento}
                                                            onChange={(e) => setComplemento(e.target.value)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorComplemento && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-8">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={formatTelefone(telefone)}
                                                            maxLength={15}
                                                            onChange={(e) => {
                                                                const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                                                setTelefone(onlyNumbers)
                                                            }}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        />
                                                        {
                                                            errorTelefone && <p className="mt-1 text-sm leading-6 text-red-600">Campo obrigatório</p>
                                                        }
                                                    </div>
                                                    {
                                                        !states.isEdit &&
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Permissão</label>
                                                            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-gray-200">
                                                                Operador do sistema
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                            onClick={handleSave}
                                        >
                                            {
                                                states.isEdit ? 'Editar' : 'Cadastrar'
                                            }
                                        </button>
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => setters.setModalUsuarios(false)}>
                                            Fechar
                                        </button>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalClientes
