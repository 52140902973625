const jsonConfig = {
  urlReact: "https://plataforma.sotertec.com.br",
  urlReactClientes: "https://paciente.sotertec.com.br",
  urlAPI: "https://psicologo.sotertec.com.br/psicologo",
  //urlAPI: 'http://localhost:7846/psicologo',
  APIKEY: `Bearer ${process.env.REACT_APP_APIKEY}`,
  limitDefault: 15,
  limitDefaultMsg: 8,
  adminPermission: "CLIENTE",
  prestadorPermission: "CLIENTEPRESTADOR",
  operacionalPermission: "CLIENTEOPERACIONAL",
  entidadeRegistro: "CRP",
  nomeClienteSecundario: "Pacientes",
};

export { jsonConfig };
// git add . && git commit -m "feax: deploy" && git push