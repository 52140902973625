import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import {
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import NavMenu from "../../../Components/NavMenu/Configuracoes";
import moment from "moment";

const ConfiguracoesContabilidade = () => {
  const { states, setters } = useContext(AuthContext);
  const { checkLogin, getConfigNegocio, putConfigNegocio } =
    useContext(AuthContextApi);
  const [configCliente, setConfigCliente] = useState({});
  const [edit, setEdit] = useState(false);

  const [nome_empresa, setNomeEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [complemento, setComplemento] = useState("");
  const [numero, setNumero] = useState("");

  const [errorNomeEmpresa, setErrorNomeEmpresa] = useState(false);
  const [errorTelefone, setErrorTelefone] = useState(false);
  const [errorCep, setErrorCep] = useState(false);
  const [errorComplemento, setErrorComplemento] = useState(false);
  const [errorNumero, setErrorNumero] = useState(false);

  const primeiroLoad = async () => {
    await checkLogin();
    await getConfigNegocio();
  };

  const formatToPhoneBrl = (value) => {
    // Checa se o número tem 9 dígitos após o DDD
    if (value?.length === 11) {
      return value.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }
    // Checa se o número tem 8 dígitos após o DDD
    else if (value?.length === 10) {
      return value.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
    }
    // Retorna o valor original caso não corresponda a nenhum formato esperado
    return value;
  };

  const formatToCpfOrCnpj = (value) => {
    if (value?.length === 11) {
      return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      return value?.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
  };

  const formatCep = (value) => {
    return value?.replace(/^(\d{5})(\d{3})/, "$1-$2");
  };

  const action = async () => {
    let error = false;

    if (nome_empresa === "") {
      setErrorNomeEmpresa(true);
      error = true;
    } else setErrorNomeEmpresa(false);

    if (telefone === "") {
      setErrorTelefone(true);
      error = true;
    } else setErrorTelefone(false);

    if (cep === "") {
      setErrorCep(true);
      error = true;
    } else setErrorCep(false);

    if (complemento === "") {
      setErrorComplemento(true);
      error = true;
    } else setErrorComplemento(false);

    if (numero === "") {
      setErrorNumero(true);
      error = true;
    } else setErrorNumero(false);

    if (error) {
      error = false;
      return;
    }

    const obj = {
      nome: nome_empresa,
      telefone,
      cep,
      complemento,
      numero,
    };

    const response = await putConfigNegocio(obj);
    if (response === true) {
      setEdit(false);
    }
  };

  useEffect(() => {
    primeiroLoad();

    return () => {
      setters.setConfiguracaoInformacoes({});
    };
  }, []);

  useEffect(() => {
    setConfigCliente(states.configuracaoInformacoes);
  }, [states.configuracaoInformacoes]);

  useEffect(() => {
    if (edit === false) {
      if (Object.keys(states.configuracaoInformacoes).lenght === "0") {
        setConfigCliente(states.configuracaoInformacoes);
      }
      setNomeEmpresa("");
      setTelefone("");
      setCep("");
      setComplemento("");
      setNumero("");
      setErrorNomeEmpresa(false);
      setErrorTelefone(false);
      setErrorCep(false);
      setErrorComplemento(false);
      setErrorNumero(false);
    } else {
      setNomeEmpresa(configCliente.nome);
      setTelefone(configCliente.telefone);
      setCep(configCliente.cep);
      setComplemento(configCliente.complemento);
      setNumero(configCliente.numero);
    }
  }, [edit]);

  return (
    <>
      <div className="flex flex-col">
        <NavMenu />
        <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Configurações da contabilidade do seu negócio
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações cadastradas sobre a contabilidade do seu negócio
          </p>
        </div>
        <div className="p-5 border rounded-[10px] hover:shadow-md mt-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Informações da sua assinatura
          </h1>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 ">
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Plano
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.plano_nome}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Dia do Pagamento
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.dia_pagamento}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Próximo Pagamento
              </h1>
              <p className="text-sm text-gray-700">
                {moment(
                  states?.configuracaoInformacoes?.assinatura?.proximo_pagamento
                ).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Status Pagamento
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura
                  ?.statusPagamento === "aguardando_pagamento"
                  ? "Aguardando Pagamento"
                  : states?.configuracaoInformacoes?.assinatura
                      ?.statusPagamento === "agendado"
                  ? "Agendado"
                  : states?.configuracaoInformacoes?.assinatura
                      ?.statusPagamento === "pagmento_verificado"
                  ? "Pagamento Verificado"
                  : states?.configuracaoInformacoes?.assinatura
                      ?.statusPagamento === "pagamento_debito_presente"
                  ? "Pagamento Débito Presente"
                  : states?.configuracaoInformacoes?.assinatura
                      ?.statusPagamento === "pagamento_nao_efetuado"
                  ? "Pagamento Não Efetuado"
                  : states?.configuracaoInformacoes?.assinatura
                      ?.statusPagamento === "cancelado"
                  ? "Cancelado"
                  : states?.configuracaoInformacoes?.assinatura
                      ?.statusPagamento ===
                    "cancelado_data_tolerancia_pagamento"
                  ? "Cancelado Data Tolerância Pagamento"
                  : ""}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Método de Pagamento
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.metodo === "pix"
                  ? "Pix"
                  : states?.configuracaoInformacoes?.assinatura?.metodo ===
                    "boleto"
                  ? "Boleto"
                  : states?.configuracaoInformacoes?.assinatura?.metodo ===
                    "cartao_credito"
                  ? "Cartão"
                  : ""}
              </p>
            </div>
            {states?.configuracaoInformacoes?.assinatura?.dataTolerancia !==
              null && (
              <div className="grid text-center">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  Data limite antes de bloqueio
                </h1>
                <p className="text-sm text-gray-700">
                  {moment(
                    states?.configuracaoInformacoes?.assinatura?.dataTolerancia
                  ).format("DD/MM/YYYY")}
                </p>
              </div>
            )}
          </div>
          <h6 className="text-sm text-blue-500 mt-8">
            Obs: Para realizar a troca de plano, entre em contato com o suporte.
          </h6>
        </div>
        <div className="p-5 border rounded-[10px] hover:shadow-md mt-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Informações do plano
          </h1>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 ">
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Área do cliente?
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura
                  ?.plano_area_cliente === 1
                  ? "Possui"
                  : "Não possui"}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Categorias para cadastro
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.plano_categoria}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Serviços para cadastro
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.plano_servicos}
              </p>
            </div>

            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Psicólogos para cadastro
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.plano_prestadores}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Pacientes para cadastro
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.plano_clientes}
              </p>
            </div>
            <div className="grid text-center">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Usuários para cadastro
              </h1>
              <p className="text-sm text-gray-700">
                {states?.configuracaoInformacoes?.assinatura?.plano_usuarios}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row  mt-4 ">
          <div className="">
            <button
              className="flex items-center flex-1 p-4 border  rounded-[10px]  hover:shadow-md mt-4"
              onClick={() => setEdit(!edit)}
            >
              <div className="flex-shrink-0">
                {!edit ? (
                  <PencilIcon
                    className="w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <XMarkIcon
                    className="w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
              <p className="text-sm font-semibold text-gray-500 truncate ml-4">
                {edit ? "Cancelar" : "Editar"}
              </p>
            </button>
          </div>
          {edit && (
            <div>
              <button
                className="flex items-center flex-1 p-4 border  rounded-[10px]  hover:shadow-md mt-4 sm:ml-5"
                onClick={() => action()}
              >
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="w-6 h-6 text-gray-300"
                    aria-hidden="true"
                  />
                </div>
                <p className="text-sm font-semibold text-gray-500 truncate ml-4">
                  Enviar
                </p>
              </button>
            </div>
          )}
        </div>
        {Object.keys(configCliente).length > 0 ? (
          <>
            {edit ? (
              <>
                <div className="flex flex-col mt-4">
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3 mt-4 border p-5 rounded-[10px] hover:shadow">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="whatsapp"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Nome da empresa
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={nome_empresa}
                        onChange={(e) => setNomeEmpresa(e.target.value)}
                      />
                      {errorNomeEmpresa && (
                        <p className="mt-2 text-sm text-red-500">
                          Campo obrigatório.
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Telefone
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={formatToPhoneBrl(telefone)}
                        onChange={(e) =>
                          setTelefone(e.target.value.replace(/\D/g, ""))
                        }
                        maxLength={11}
                      />
                      {errorTelefone && (
                        <p className="mt-2 text-sm text-red-500">
                          Campo obrigatório.
                        </p>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Cep
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={formatCep(cep)}
                        onChange={(e) =>
                          setCep(e.target.value.replace(/\D/g, ""))
                        }
                        maxLength={8}
                      />
                      {errorCep && (
                        <p className="mt-2 text-sm text-red-500">
                          Campo obrigatório.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-4 border p-5 rounded-[10px] hover:shadow">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Numero
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={numero}
                        onChange={(e) =>
                          setNumero(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                    {errorNumero && (
                      <p className="mt-2 text-sm text-red-500">
                        Campo obrigatório.
                      </p>
                    )}
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Complemento
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={complemento}
                        onChange={(e) => setComplemento(e.target.value)}
                      />
                      {errorComplemento && (
                        <p className="mt-2 text-sm text-red-500">
                          Campo obrigatório.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="p-5 border rounded-[10px] hover:shadow-md mt-4">
                  <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
                    <div className="grid gap-4">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Nome da Empresa
                      </h1>
                      <p className="text-sm text-gray-700">
                        {configCliente.nome}
                      </p>
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        CPF/CNPJ
                      </h1>
                      <p className="text-sm text-gray-700">
                        {formatToCpfOrCnpj(configCliente.cpf_cnpj)}
                      </p>
                    </div>
                    <div className="grid gap-4">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Telefone
                      </h1>
                      <p className="text-sm text-gray-700">
                        {formatToPhoneBrl(configCliente.telefone)}
                      </p>
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Nome do responsável
                      </h1>
                      <p className="text-sm text-gray-700">
                        {configCliente.nome_responsavel}
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Endereço Completo
                      </h1>
                      <p className="text-sm text-gray-700">
                        {`${configCliente.rua}, ${configCliente.numero}, ${configCliente.complemento}, ${configCliente.bairro}, ${configCliente.cidade}, ${configCliente.estado}`}
                      </p>
                      <h1 className="text-base font-semibold leading-6 text-gray-900">
                        CEP
                      </h1>
                      <p className="text-sm text-gray-700">
                        {`${formatCep(configCliente.cep)}`}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-3 mt-4">
              <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="w-6 h-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm font-semibold text-gray-500 truncate">
                          Nenhuma informação encontrada
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConfiguracoesContabilidade;
