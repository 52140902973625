import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import Calendario from '../../Components/Calendario'
import ListaTarefas from '../../Components/ListaDeTarefas'
import Modal from '../../Components/Modal/Marcacao/MinhaMarcacao'
import ModalShow from '../../Components/Modal/Marcacao/MarcacaoShow'
import { useNavigate, useParams } from 'react-router-dom';




const Agenda = () => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getServicosPrestador,
        getPacientesCpf,

        getMinhaAgendaMonth,
        getMeuDayCompromissos,
        getMeuDayCompromissosMore,

        postMeuCompromisso,
        cancelMeuCompromisso,
        deletarMeuCompromisso,
        iniciarMeuServico,
        putMeuCompromisso
    
    } = useContext(AuthContextApi)
    const [cumprimento, setCumprimento] = useState('')
    const [nomeUsuario, setNomeUsuario] = useState('')

    const navigate = useNavigate()
    const { token } = useParams()


    const getGreeting = () => {
        const hour = moment().hour(); // Pega a hora atual
        if (hour < 12) {
            setCumprimento('Bom dia')
        } else if (hour < 18) {
            setCumprimento('Boa tarde')
        } else {
            setCumprimento('Boa noite')
        }
    };



    const primeiroLoad = async () => {
        await checkLogin()
        await getMinhaAgendaMonth(states.month)
        await getMeuDayCompromissos(states.day)
    }

    const truncatFunction = (str, n) => {
        return str.length > n ? str.substr(0, n - 1) + '...' : str;
    }

    const nextMonth = () => {
        const newData = moment(states.month).add(1, 'month').format('YYYY-MM')
        setters.setMonth(newData)
        getMinhaAgendaMonth(newData)
    }

    const prevMonth = () => {
        const newData = moment(states.month).subtract(1, 'month').format('YYYY-MM')
        setters.setMonth(newData)
        getMinhaAgendaMonth(newData)
    }

    const tarefasDay = async (day) => {
        setters.setDay(day.day)
        setters.setOffsetTarefas(0)
        await getMeuDayCompromissos(day.day)
    }


    const loadMoreTarefas = async () => {
        await getMeuDayCompromissosMore(states.day)
    }


    const addEvento = async (data) => {
        await getServicosPrestador()
        setters.setIsEdit(false)
        setters.setModalTarefas(true)
        setters.setPacientes({})

    }

    const editEvento = async (data) => {
        await getServicosPrestador()
        if (data.cpf !== null) {
            await getPacientesCpf(data.cpf)
        }
        setters.setIsEdit(true)
        setters.setTarefas(data)
        setters.setModalTarefas(true)
        setters.setPacientes({})

    }

    const showEvento = async (data) => {
        setters.setModalTarefasShow(true)
        setters.setTarefas(data)
    }


    const actionAddEvento = async (data) => {
        setters.setModalTarefas(false)
        if (!states.isEdit) {
            await postMeuCompromisso(data)
        } else {
            setters.setTarefas({})
            setters.setIsEdit(false)
            await putMeuCompromisso(data, data.id)
        }
    }

    const loadMoreCategoriaPrestador = async () => {
        await getServicosPrestador()
    }

    const cancelarEvento = async (data) => {
        setters.setModalExecucoesServicoPaciente(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(true)
        setters.setMsgModalMsg(`Deseja cancelar o agendamennto ${data.titulo} ?`)
        setters.setDescModalMsg(`Após a confirmação o agendamento será cancelado e não poderá ser recuperado.`)
        setters.setTitleButtonModalMsg('Cancelar')
        setters.setFunctionModalMsg(() => async () => {
            await cancelMeuCompromisso(data.id)
            setters.setModalMsg(false)
        })
    }

    const deletarEvento = async (data) => {
        setters.setModalExecucoesServicoPaciente(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(true)
        setters.setMsgModalMsg(`Deseja deletar o agendamennto ${data.titulo} ?`)
        setters.setDescModalMsg(`Após a confirmação o agendamento será deletado e não poderá ser recuperado.`)
        setters.setTitleButtonModalMsg('Deletar')
        setters.setFunctionModalMsg(() => async () => {
            await deletarMeuCompromisso(data.id)
            setters.setModalMsg(false)
        })
    }

    const initMeuCompromisso = async (data) => {
        setters.setModalExecucoesServicoPaciente(false)
        setters.setModalMsg2(true)
        setters.setPerigoModalMsg2(false)
        setters.setMsgModalMsg2(`Deseja iniciar o serviço ${data.servico_nome}?`)
        setters.setDescModalMsg2(`Após a confirmação o serviço será iniciado`)
        setters.setTitleButtonModalMsg2('Confirmar')
        setters.setFunctionModalMsg2(() => async () => {
            const response = await iniciarMeuServico(data)
            setters.setModalMsg2(false)
            if (response.status === true && response.confirm) {
                setters.setModalMsg2(true)
                setters.setPerigoModalMsg2(true)
                setters.setMsgModalMsg2(response.msg)
                setters.setDescModalMsg2(response.msg)
                setters.setTitleButtonModalMsg2('Confirmar início do serviço')
                setters.setFunctionModalMsg2(() => async () => {
                    const response = await iniciarMeuServico({ ...data, confirmCancel: "true" })
                    setters.setModalMsg2(false)
                    if (response.status === true) {
                        navigate(`/servicos/${token}/paciente/${data.clienteSecundario_id}/atendimento/${response.prontuario}`)
                    }
                })
                setters.setFunctionBtnModalMsg2(() => async () => {
                    await iniciarMeuServico({ ...data, confirmCancel: "false" })
                    setters.setModalMsg2(false)
                })
            }
            if (response.status === true && !response.confirm) {
                navigate(`/servicos/${token}/paciente/${data.clienteSecundario_id}/atendimento/${response.prontuario}`)
            }
        })

        setters.setFunctionBtnModalMsg2(() => async () => {
            setters.setModalMsg2(false)
        })
    }


    useEffect(() => {
        if (states.dataCategoriaPrestador.length > 0) {
            loadMoreCategoriaPrestador()
        }
    }, [states.offsetCategoriaPrestador])



    useEffect(() => {
        if (states.dataTarefas.length > 0 && states.offsetTarefas > 0) {
            loadMoreTarefas()
        }
    }, [states.offsetTarefas])

    useEffect(() => {
        primeiroLoad()
        getGreeting()
        const nome = localStorage.getItem('nome')
        setNomeUsuario(nome)

        return () => {
            setters.setDataAgenda([])
            setters.setDataTarefas([])
            setters.setLimiteBuscaTarefas(false)
            setters.setTarefas({})
            setters.setOffsetCategoriaPrestador(0)
            setters.setLimiteBuscaCategoriaPrestador(false)
            setters.setPaciente({})
            setters.setDataPrestadores([])
            setters.setOffsetPrestadores(0)
            setters.setLimiteBuscaPrestadores(false)

        }

    }, [])




    return (
        <div className="flex flex-col">
            <div className="flex-1 p-4 border  hover:shadow-md">

                <div className='flex items-center justify-between'>
                    <h1 className="hidden sm:flex text-2xl font-bold leading-7 text-gray-900 sm:leading-9">
                        {cumprimento}, {nomeUsuario}
                    </h1>
                    <h1 className="flex sm:hidden text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                        {cumprimento}, {truncatFunction(nomeUsuario, 18)}
                    </h1>
                    <CheckCircleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        aria-hidden="true"
                    />
                </div>
                <p className="text-sm text-gray-500 mt-4 ">
                    Seja bem-vindo ao Painel de agenda, aqui você pode visualizar os seus atendimentos do dia, semana e mês.
                </p>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 mt-4">
                <div className="flex items-center p-4 border hover:shadow-md col-span-3">
                    <Calendario data={states.dataAgenda} nextMonth={nextMonth} prevMonth={prevMonth} states={states} nextDay={tarefasDay} add={addEvento} />
                </div>
                <div className="flex items-center p-4 border hover:shadow-md">
                    <ListaTarefas states={states} data={states.dataTarefas} exec={initMeuCompromisso}
                        setters={setters} show={showEvento} edit={editEvento} cancel={cancelarEvento} del={deletarEvento} prestador={true} />
                </div>
            </div>
            <Modal states={states} setters={setters} get={getPacientesCpf} action={actionAddEvento} />
            <ModalShow states={states} setters={setters} />
        </div>

    );
}


export default Agenda;